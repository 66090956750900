import { LoadingScreen } from '@ppui/ui-components'
import { isNotNil } from 'ramda-adjunct'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useHandleError } from './lib/hooks/useHandleError'
import { useGetUserProfile } from './lib/queries/useGetUserProfile'
import { postApplicationNotEnabledNotification } from './services/users'

export const RequireApplicationEnabled = ({ children }: { children: JSX.Element }): JSX.Element => {
  const location = useLocation()

  const { isLoading, error, data: user } = useGetUserProfile()

  const errorComponent = useHandleError(error)

  if (isNotNil(errorComponent)) return errorComponent

  if (isLoading || user === undefined) {
    return <LoadingScreen />
  }

  if (!user.isIoiEnabled) {
    void postApplicationNotEnabledNotification(location?.pathname ?? '')
    return <Navigate to="/request-access" replace />
  }

  return <div>{children}</div>
}
